import { graphql, Link, useStaticQuery } from "gatsby";
import { HelmetDatoCms } from "gatsby-source-datocms";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import "../theme/index.css";
import GlobalStyle from "../theme/global";
import { theme } from "../theme/theme";
import { Footer } from "./Footer";
import { Navbar } from "./Navbar";
import FlatHeader from "./FlatHeader";

export const Layout = ({ children, locales }) => {
  const data = useStaticQuery(graphql`
    query {
      site: datoCmsSite {
        faviconMetaTags {
          tags
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyle />
      <HelmetDatoCms favicon={data.site.faviconMetaTags}>
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </HelmetDatoCms>
      <Header className="flex align-center">
        <Navbar />
      </Header>
      <FlatHeader />
      {children}
      <Footer />
    </>
  );
};

const Header = styled.header`
  width: 100%;
  z-index: 1;
  height: 110px;
  font-weight: 500;
  font-size: 17px;
  flex-direction: column;
  @media (max-width: 600px) {
    height: 70px;
  }
  a {
    font-size: 20px;
    font-family: "Inter", sans-serif;
    @media (max-width: 600px) {
      font-size: 16px;
    }
  }
  nav {
    @media (max-width: 600px) {
      justify-content: flex-end;
    }
    // Cool Hover Effect
    a {
      padding-left: 10px;
      padding-right: 10px;
      &.active span {
        background: #000;
        color: #fff;
      }
    }
    a span {
      position: relative;
    }
    a span::before {
      content: "";
      background-color: #000;
      position: absolute;
      left: 0;
      bottom: 3px;
      width: 100%;
      height: 0px;
      z-index: -1;
      transition: all 0.15s ease-in-out;
    }
    a:hover span {
      color: #fff;
      transition: all 0.2s;
    }
    a:hover span:before {
      bottom: 0;
      height: 100%;
    }
  }
  > .container {
    height: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    @media (max-width: 900px) {
      justify-content: space-between;
      > div,
      > nav {
        width: fit-content;
      }
    }

    @media (max-width: 320px) {
      flex-direction: column;
      > div,
      > nav {
        justify-content: center;
      }
      > div {
        margin-top: 5px;
      }
    }
  }
`;
