import React, { useState, useEffect } from "react";

export const useTagStringToArray = (string) => {
  if (!string) return [];
  let arr = string.split(/[,]+/);
  const result = arr.map((element) => {
    return element.trim();
  });

  return result;
};
