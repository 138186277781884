import { Link } from "gatsby";
import React, { useContext, useEffect, useState } from "react";
import { useScrollData } from "scroll-data-hook";
import { Navbar } from "./Navbar";

// TODO: Remove menu from props
const FlatHeader = () => {
  const [show, setShow] = useState(false);
  const { direction, position } = useScrollData();

  const threshold = 400;

  useEffect(() => {
    if (position.y < threshold) {
      setShow(false);
      return;
    }
    if (direction.y === "up" && position.y > threshold) {
      setShow(true);
      return;
    }
    if (direction.y === "down") {
      setShow(false);
      return;
    }
    console.log(show);
  }, [direction]);

  return (
    <header
      className={show ? "" : "hide"}
      css={`
        height: 70px;
        border-bottom: 0.8px dashed #000;
        background: #fff;
        z-index: 3;
        top: 0;
        width: 100%;
        display: flex;
        position: fixed;
        transition: all 0.3s;
        &.hide {
          transition: all 0.3s;
          transform: translateY(-100%);
          opacity: 0;
        }
      `}
    >
      <Navbar />
    </header>
  );
};

export default FlatHeader;
