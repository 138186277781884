import { graphql, Link, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import styled from "styled-components";
import { theme } from "../theme/theme";
import { GatsbyImage } from "gatsby-plugin-image";

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      info: datoCmsInfo {
        email
        instagram
        linkedin
        cv
      }
    }
  `);
  return (
    <FooterStyled className="flex container padding space-between">
      <div className="Content flex">
        <p className="Footnote">
          © {new Date().getFullYear()} (Studio) Jacob Stewart
        </p>

        <div className="flex Links justify-end">
          <a href={`mailto:${data.info.email}`}>Email</a>
          <a href={data.info.instagram} target="_blank" rel="noreferrer">
            Instagram
          </a>
          <a href={data.info.linkedin} target="_blank" rel="noreferrer">
            Linkedin
          </a>
          <a href={data.info.cv} target="_blank" rel="noreferrer">
            CV
          </a>
        </div>
      </div>
    </FooterStyled>
  );
};

const FooterStyled = styled.footer`
  padding-bottom: 15px;
  .Content {
    padding-top: 20px;
    border-top: 1px solid #000;
    @media (max-width: 700px) {
      text-align: center;
      flex-direction: column-reverse;
    }
  }
  .Footnote {
    font-size: 13px;
    line-height: 16px;
    min-width: fit-content;
  }
  .Links {
    @media (max-width: 700px) {
      justify-content: center;
    }

    a:not(:last-child) {
      margin-right: 40px;
    }
    a {
      font-size: 20px;
      @media (max-width: 700px) {
        font-size: 16px;
        margin-right: 10px !important;
        margin-left: 10px;
        margin-bottom: 20px;
      }
    }
  }
`;
