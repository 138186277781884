import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

const GlobalStyle = createGlobalStyle`

/* CSS Framework */

// Prevent scroll to top effect on page transitions
:root {
    @media (prefers-reduced-motion: no-preference) {
      scroll-behavior: auto;
    }
}

.flex {
  display: flex;
  width: 100%;
}
.inline-flex {
  display: inline-flex;
}
.container {
  width: 100%;  
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;  
}
.padding {
  padding-left: 15px;
  padding-right: 15px;  
  @media (min-width: 600px) {
    padding-left: 30px;
     padding-right: 30px;
  }
  @media (min-width: 900px) {
    padding-left: 40px;
     padding-right: 40px;
  }
  @media (min-width: 1200px) {
    padding-left: 60px;
     padding-right: 60px;
  }
}
.col {
  display: flex;
  flex-direction: column;
}
.row {
  display: flex;
  flex-direction: row;
}
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}
.space-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.align-center {
  align-items: center;
}
.wrap {
  flex-wrap: wrap;
}
.text-center {
  text-align: center;
}
.allCenter {
  justify-content: center;
  align-items: center;
}
.inline-flex {
  display: inline-flex;
}
.ml-auto {
  margin-left: auto;
}

/* Theme */

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;  
  padding-left: 38px;
  padding-right: 38px;
  height: 61px;
  border-radius: 13px;  
  font-weight: 700;
  text-transform: uppercase;
  font-size: 18px;  
  width: fit-content;  
  letter-spacing: -0.015em;
  border: 2px solid ${theme.primary};
  background: ${theme.primary};
  color: #fff;
  &:hover {
    opacity: 0.9;
  }
}
.btn.primary {
  background: ${theme.primary};
  color: #fff;
  &:hover {
    transition: all 0.2s ease 0s;
    opacity: 0.92;
  }
}
.btn.accent {
  background: ${theme.accent};
  border-color: ${theme.accent};
  color: #fff;
  &:hover {
    transition: all 0.2s ease 0s;
    opacity: 0.92;
  }
}
.btn.secondary {
  background: none; 
  color: ${theme.primary};
}
.btn.white {
  background: #fff; 
  color: ${theme.primary};
}

/* Form */

form {
  input {
    min-height: 61px;
    border-radius: 13px;
    padding: 10px;
    font-size: 20px;
  }
}
/* HMTL & Body */

body {
  font-family: 'Inter', sans-serif;
  color: #000;  
}

main {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Fonts */

p,
li {  
  font-size: 18px;
  line-height: 22x;  
  margin-bottom: 10px;  
}
h1,
h2,
h3 {  
  font-family: 'Inter', sans-serif;
  color: ${theme.primary};
  font-weight: 400;
  letter-spacing: -0.015em;
}
h1 {
  font-size: 44px;
  line-height: 1.2;
  font-weight: 600;  
  @media (max-width: 600px) {
    font-size: 32px;
  }
}

h2 {
    font-size: 55px;
    line-height: 1.15;
    @media (max-width: 800px) {
      font-size: 28px;
    }
}
h3 {
  font-size: 26px;
  font-weight: 600;      
  @media (max-width: 800px) {
      font-size: 22px;
    }
}

/* Article */

article p {
  font-size: 20px;
  margin-bottom: 28px;
  line-height: 24.2px;
}
article img {
  max-width: 100%;

}
article ul li,
article ol li {
  font-size: 20px;  
}
article h2 {
  font-size: 28px;
  font-weight: 600;
  padding-top: 30px;
  padding-bottom: 20px;
}
article h3 {
  line-height: 34px;
  font-size: 22px;
  font-weight: 600;
  padding-top: 20px;
  margin-bottom: 15px;
  color: #000;
  line-height: 1.3;
}
`;

export default GlobalStyle;
